.PdfDocument html body ul > li {
  position: relative;
}

.PdfDocument html body ul > li::before {
  color: black;
  font-style: normal;
  position: absolute;
  left: -15px;
}
